import React from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage} from "react-intl";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";

export function AddAddons() {

	const history = useHistory();

	function goBack() {
		history.push("/menu/all")
	}

	return (<>
		<div className="row">

    		<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-left-panel-2.svg")}/>
                  	</span> 
					<span>
						<FormattedMessage id="PAGE.ADADDONS.DESC" />
					</span>
					<span className="svg-icon menu-icon goBack" onClick={goBack}>
						<i className="fa fa-long-arrow-alt-left"></i>
			    	</span>
				</div>
			</div>

		</div>
	</>);

}