import React, { useState, useEffect,useRef } from "react";
import ReactDOM from "react-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import QRCodeStyling from "qr-code-styling";

import {connect,useSelector,useDispatch} from 'react-redux'
import * as bredux from '../Business/_redux/businessRedux'

import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from "react-router-dom";
import swal from 'sweetalert';

import { useFormik,useFormikContext } from "formik";
import * as Yup from "yup";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { CopyToClipboard } from "react-copy-to-clipboard";
//import "react-google-places-autocomplete/dist/assets/index.css";

import Geocode from "react-geocode";
import BlockUI from "../Component/BlockUI";


function AddBranch(props) {

	const history = useHistory();
	const location = useLocation();

	//const bs_id = location.state.bs_id;
	function viewBusiness() {
	    history.push("/business/all")
	}

	const [initialValues, setInitialValues] = useState({ 
		business: "",
		name: "",
		phone_number:"",
		address: "",
		area:"",
		city: "",
		country:178
	});

	const qr_box_ref = useRef(null);
	  const globalState  = useSelector(state=>state.business)

	  const [allbusinesses, setdata] = useState([]);
	  const [allcountries, setCountryData] = useState([]);
	  const [input_address,setAddress] = useState('')
	  const {user} = useSelector(state => state.auth);
	  const [addlats, SetAddlats] = useState("");
	  const [addlong, SetAddlong] = useState("");
	  const user_id = user.id; 

  
	const [state, setState] = React.useState({
		checkedA: true,
		latitude:"",
		longitude: "",
		getAddress: "",
		getAdds:null,
		getLat:null,
		getLng:null,
		address:"",
		address2:"",
		area: "",
		city:"",
		state:"",
		zip:"",
		country:"",
		valid_addr: "",
		addressMsg: "hide",
		valid_area: "",
		areaMsg:"hide",
		valid_city: "",
		cityMsg:"hide"
	  });

	  const [formik_values, setValues] = React.useState({
		name: '',
		address:"",
		address2:"",
		business: "",
		area: "",
		city:"",
		state:"",
		zip:"",
		country:178
	  });

	  useEffect( ()=>{
		  //console.log(globalState.all_countries)
		  setdata(globalState.all_businesses)
		  setCountryData(globalState.all_countries)
	  });

	  useEffect(() => {
	

		
		generateQRCode();
	  },[globalState.new_branch_url])



	  const generateQRCode = async ()=>{

		var url = globalState.new_branch_url
		
			if	(url!= "" && url.length>3){
	const qrCode =await  new QRCodeStyling({
		width: 1900,
		height: 1900,
		
		image: require('./qr-lite.png'),
		dotsOptions: {
		  color: "#171919",
		  type: "dots"
		},
	  
		qrOptions: {
			typeNumber: 0,
			errorCorrectionLevel: "H",
			mode: "Byte"
		},
		cornersSquareOptions: {
		  type: "extra-rounded"
		},
		cornersDotOptions: {
		  type: "dot"
		},
		imageOptions: {
			crossOrigin: "anonymous",
			imageSize: 0.4,
			margin: 11
		}
	  });

	  qrCode.append(qr_box_ref.current);
	await  qrCode.update({
		data: url
	  });


	  setTimeout(() => {		  
		  var code = qrCode._canvas.getCanvas().toDataURL()		

		  var branch = url.split('=')[1]
			
		  props.uploadBrQR({qr_data:code, user:user.id, branch_id:branch, bs_id: formik.values.business,push:history.push})
	  }, 1000);

	  //qrCode.download()


}
		

	  }

	 


  
	  useEffect( ()=>{
			props.getBusinesses(user_id)
			
		  	Geocode.setApiKey("AIzaSyBEuO4GQ_eNXuf5uGdbGcl67OoHhgoSrNI");
			Geocode.enableDebug();

			Geocode.fromAddress(state.getAddress).then(
				response => {
					const { lat, lng } = response.results[0].geometry.location;
					//console.log(lat, lng)
					SetAddlats(lat);
					SetAddlong(lng);

					Geocode.fromLatLng(lat, lng).then(
						response => {
							const address = response.results[0];
							const format_address = response.results[0].formatted_address;
							//console.log(address.address_components)
							//console.log(format_address)
		
							const formatGeocode = (result) => {
								//console.log(result.address_components)
								if (result) {
								  var address = '',
									city = '',
									state = '',
									area = '',
									block = '',
									zip = '',
									country = '';
							  
									for (var i = 0; i < result.address_components.length; i++) {
										var addr = result.address_components[i];
										// check if this entry in address_components has a type of country
										if (addr.types[0] == 'country') country = addr.long_name;
										else if (addr.types[0] == 'street_number')
										// address 1
										address = address + addr.long_name;
										else if (addr.types[0] == 'establishment')
										address = address + ' ' + addr.long_name;
										else if (addr.types[0] == 'neighborhood')
										// address 3
										address = address + ' ' + addr.long_name;
										else if (addr.types[0] == 'route')
										// address 2
										address = address + ' ' + addr.long_name;
										// else if (addr.types[0] == 'premise')
										//   // address 3
										//   address = address + ' ' + addr.long_name;
										else if (addr.types[0] == 'postal_code')
										// Zip
										zip = addr.short_name;
										else if (addr.types[1] == 'sublocality')
										// area
										area = addr.long_name;
										else if (addr.types[0] == ['administrative_area_level_1'])
										// State
										state = addr.long_name;
										else if (addr.types[0] == ['locality'])
										// City
										city = addr.long_name;
									}
									//console.log(allcountries)
									const cn_id = allcountries.find(post=>post.country_name===country);
									//console.log("Country ID: "+cn_id.id)
									// allcountries.map((option, index) => (
									// 	console.log(option.id, option.country_name)
									// ))
									setState({...state, address:address, area:area, city:city, state:state, zip:zip, country:cn_id.id});
									setValues({...formik_values, address:address, area:area, city:city, state:state, zip:zip, country:cn_id.id});
									console.log("Address: "+address+" - Area: "+area+" - City: "+city+" - State: "+state+" - Zip: "+zip+" - Country: "+country);
									
									return {
										city,
										area,
										address,
										state,
										zip,
										country,
									};
								}
							}
		
							formatGeocode(address);
						},
						error => {
							console.error(error);
						}
					);
				},
				error => {
					console.error(error);
				}
			);
	  } , [state.getAddress]);
	  
	  useEffect( ()=>{
		  props.getCountries()
	  } , []);

	function viewBusiness() {
	    history.push("/business/all")
	}


	  const formik = useFormik({
    	initialValues,
    	validationSchema: BranchSchema,
    	onSubmit: (formik_values, { setStatus, setSubmitting }) => {

			if(state.address == "") {
				setState({...state, valid_addr: "is-invalid", addressMsg: "show"});
				swal({
					title: "Something Wrong",
					text: "Please Add Branch Address!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else if(state.address !== "") {
				setState({...state, valid_addr: "valid", addressMsg: "hide"});
			}

			if(state.area == "") {
				setState({...state, valid_area: "is-invalid", areaMsg: "show"});
				swal({
					title: "Something Wrong",
					text: "Please Add Branch Area!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else {
				setState({...state, valid_area: "valid", areaMsg: "hide"});
			}

			

			if(state.city == "") {
				setState({...state, valid_city: "is-invalid", cityMsg: "show"});
				swal({
					title: "Something Wrong",
					text: "Please Add Branch Area!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else {
				setState({...state, valid_city: "valid", cityMsg: "hide"});
			}
			
			props.addBranch({ 
				user_id :user.id,
				business_id : formik_values.business,
				name : formik_values.name,
				phone_number: formik_values.phone_number,
				address1 :state.address,
				address2 :state.address2 == undefined ? "" : state.address2,
				area :state.area,
				city : state.city,
				zip:state.zip,
				state:state.state,
				country_id :formik_values.country,
				latitude:addlats,
				longitude:addlong
			});

			// console.log(
			// 	"user_id: "+user.id,
			// 	"business_id: "+formik_values.business,
			// 	"name: "+formik_values.name,
			// 	"phone_number: "+formik_values.phone_number,
			// 	"address1: "+state.address,
			// 	"address2: "+state.address2,
			// 	"area: "+state.area,
			// 	"city: "+state.city,
			// 	"zip: "+state.zip,
			// 	"state: "+state.state,
			// 	"country_id :"+formik_values.country,
			// 	"latitude: "+addlats,
			// 	"longitude: "+addlong
			// )



			//TEMP , UNCOMMENT FOR LATER
			
			// setTimeout(() => {
			// 	swal({
			// 		title: "Branch Added",
			// 		text: "Branch saved successfully!",
			// 		icon: "success",
			// 		button: null,
			// 		timer: 3000
			// 	});
			// }, 100);
			// setTimeout(() => {
			// 	history.push({
			// 		pathname: '/business/branches',
			// 		state: {
			// 			bs_id: formik_values.business
			// 		}
			// 	});
			// }, 3500);
	    },
	});

	const useStyles = makeStyles(theme => ({
		container: {
		    display: 'flex',
		    flexWrap: 'wrap',
		},
		textField: {
		    marginLeft: theme.spacing(1),
		    marginRight: theme.spacing(1),
		},
		dense: {
		    marginTop: theme.spacing(2),
		},
		menu: {
		    width: 200,
		},
	}));

	const classes = useStyles();

	const handleChange = name => event => {
		console.log(name,event.target.value)
	    setState({ ...state, [name]: event.target.value, valid_addr: '',addressMsg: "hide",valid_area: "", areaMsg:"hide",valid_city: "",cityMsg:"hide" });
        setValues({ ...formik_values, [name]: event.target.value });
	};  
	  
	
	const [loading, setLoading] = useState(false);

  	const BranchSchema = Yup.object().shape({
    	business: Yup.string()
	      	.required("Select Business"),
    	name: Yup.string()
	      	.required("Enter a branch name"),
		phone_number: Yup.string()
			.required("Phone Number require"),
    	country: Yup.string()
	      	.required("Select Country"),
	});

	const enableLoading = () => {
    	setLoading(true);
	};

	  const disableLoading = () => {
	    setLoading(false);
	};

	const getInputClasses = (fieldname) => {
	    if (formik.touched[fieldname] && formik.errors[fieldname]) {
	      return "is-invalid";
	    }

	    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
	      return "is-valid";
	    }

	    return "";
	};


	function goBack() {
		history.push("/business/all")
	}

	const [rows, setRows] = useState([]);
	const [add, setAdd] = useState(null);
	const [pid, setPid] = useState(null);

	return (<>
		<div className="row">

    		<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
                  	</span>  
					<span>
						<FormattedMessage id="PAGE.LINK.ADDBRANCH.DESC" />
					</span>
					<span className="svg-icon menu-icon goBack hide" onClick={goBack}>
						<i className="fa fa-long-arrow-alt-left"></i>
			    	</span>
				</div>
			</div>

			{
				globalState.is_loading === true?
				<BlockUI />
				: <div> </div>
			}
			<div className="card card-custom gutter-b col-md-12 innerBlks">
				<div className="card-header">
					<div className="card-title"><h3 className="card-label"><FormattedMessage id="PAGE.LINKS.ADDBRANCH.WIDGET_TITLE" /></h3></div>
				</div>
		        <div className="card-body">
		        	
	        		<div className="row">
		                <div className="col-md-6 ffield">
		                	<form 
				        		autoComplete="off"
						        onSubmit={formik.handleSubmit}
						        className="form fv-plugins-bootstrap fv-plugins-framework"
				        	>
				        		{formik.status ? (
						          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
						            <div className="alert-text font-weight-bold">{formik.status}</div>
						          </div>
						        ) : (
						          <div>
						          </div>
						        )}

						        <div className="fieldBlk">
									<TextField
				                        select
				                        name="business"
				                        variant="outlined"
				                        label="Select Business"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "business"
							            )}`}
				                        value={formik_values.business}
				                        {...formik.getFieldProps("business")}
				                    >
				                        {allbusinesses.map(option => (
				                          <MenuItem key={option.id} value={option.id}>
				                            {option.name}
				                          </MenuItem>
				                        ))}
				                    </TextField>
				                    {formik.touched.business && formik.errors.business ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.business}</div>
							            </div>
							          ) : null}
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.LINKS.ADDBRANCH.BUSINESS.HELP" />
				                    </p>
						        </div>
				                	
						        <div className="fieldBlk">
				                    <TextField
				                        name="name"
				                        label="Branch Name"
				                        type="text"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "name"
							            )}`}
				                        variant="outlined"
				                        value={formik_values.name}
	        							{...formik.getFieldProps("name")}
				                    />
				                    {formik.touched.name && formik.errors.name ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.name}</div>
							            </div>
							          ) : null}
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.BRANCH.HELP" />
				                    </p>
			                    </div>

								<div className="fieldBlk">
				                    <TextField
				                    	label="Phone No"
				                        name="phone_number"
				                        type="text"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "phone_number"
							            )}`}
				                        value={formik_values.phone_number}
	        							{...formik.getFieldProps("phone_number")}
				                    />
				                    {formik.touched.phone_number && formik.errors.phone_number ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.phone_number}</div>
							            </div>
							          ) : null}
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.PHONE.HELP" />
				                    </p>
			                    </div>

								<div className="gaddress fieldBlk">
									<i className="fa fa-search-location"></i>
									<GooglePlacesAutocomplete
										placeholder="Search Location Address"
										inputStyle={{
											height: 52,
											fontSize: 14
										}}
										suggestionsStyles={{
											container: {
												padding: 16,
												background: "#efefef"
											},
											suggestion: {
												background: "#eee",
												cursor: "pointer"
											},
											suggestionActive: {
												background: "#bbb"
											}
										}}
										onSelect={(result) => {
											const { description, place_id } = result;
											setRows([{ description, place_id }, ...rows]);
											var address = result.description;
											var pl_id = result.place_id;
											setState({...state, getAddress: address});
											setPid(pl_id);
											setAdd(result.description);
										}}
									/>
									<p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.SEARCHADDRESS.HELP" />
				                    </p>
									<div style={{display: "none"}} className="google-result">
										{addlats}, {addlong} <br />
										{state.address} {state.area} {state.city} {state.country}
									</div>
								</div>

			                    <div className={`fieldBlk valid-address `+ state.valid_addr}>
				                    <TextField
				                        id="address"
				                        name="address"
				                        label="Branch Address"
				                        type="text"
				                        margin="normal"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
								            "address"
							            )}`}
				                        variant="outlined"
										onChange={handleChange("address")}
				                        value={formik_values.address}
				                    />
									<span className={`fv-help-block `+ state.addressMsg}>Branch Address Required</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.ADDRESS.HELP" />
				                    </p>
			                    </div>

								<div className="fieldBlk">
				                    <TextField
				                        id="address2"
				                        name="address2"
				                        label="Address Line 2"
				                        type="text"
				                        margin="normal"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
								            "address2"
							            )}`}
				                        variant="outlined"
										onChange={handleChange("address2")}
				                        value={formik_values.address2}
				                    />
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.ADDRESSLINE2" />
				                    </p>
			                    </div>

			                    <div className={`fieldBlk ` + state.valid_area}>
				                    <TextField
				                        id="area"
				                        name="area"
				                        label="Area"
				                        type="text"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
								            "area"
							            )}`}
										onChange={handleChange("area")}
				                        value={formik_values.area}
				                    />
									<span className={`fv-help-block `+ state.areaMsg}>Branch Area Required</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.AREA.HELP" />
				                    </p>
			                    </div>

			                    <div className={`fieldBlk ` + state.valid_city}>
				                    <TextField
				                        id="city"
				                        name="city"
				                        label="City"
				                        type="text"
				                        variant="outlined"
		    							className={`form-control h-auto py-0 px-0  ${getInputClasses(
								            "city"
										)}`}
										onChange={handleChange("city")}
				                        value={formik_values.city}
				                    />
				                    <span className={`fv-help-block `+ state.cityMsg}>Branch City Required</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.CITY.HELP" />
				                    </p>
			                    </div>

			                    <div className="fieldBlk">
				                    <TextField
				                        select
				                        name="country"
				                        variant="outlined"
				                        label="Select Country"
							            className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "country"
										)}`}
				                        {...formik.getFieldProps("country")}
										onChange={handleChange("country")}
				                        value={formik_values.country}
				                    >
				                        {allcountries.map(option => (
				                          <MenuItem key={option.id} value={option.id}>
				                            {option.country_name}
				                          </MenuItem>
				                        ))}
				                    </TextField>
				                    {formik.touched.country && formik.errors.country ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.country}</div>
							            </div>
							          ) : null}
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.COUNTRY.HELP" />
				                    </p>
			                    </div>

			                    <div className="class-submit clear">
		  					    	<Button 
							            type="submit"
							            //disabled={formik.isSubmitting}
		  					    		variant="contained" 
		  					    		color="primary" 
		  					    		className={classes.button}
		  					    	>
			  					        <FormattedMessage id="SAVE" />
			  					        {loading && <span className="ml-3 spinner spinner-white"></span>}
			  					    </Button>
			  					    <Button variant="contained" className={classes.button} onClick={viewBusiness}>
			  					        <FormattedMessage id="CANCEL" />
			  					    </Button>
		  					    </div>
	  					    </form>
		                </div>
		                <div className="col-md-6 ">
		                	<img src="/media/images/branch.jpg" />
		                </div> 
	                </div>						    
				      
		        </div>
		    </div>    

		</div>
	</>);

}

export default connect(null, bredux.actions)(AddBranch);