import React, { useState, useEffect,useRef } from "react";
import ReactDOM from "react-dom";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { FormattedMessage} from "react-intl";
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import QRCodeStyling from "qr-code-styling";

import {connect,useSelector,useDispatch} from 'react-redux'
import * as bredux from './_redux/businessRedux'

import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from "react-router-dom";
import swal from 'sweetalert';
import { useFormik } from "formik";
import * as Yup from "yup";

import GooglePlacesAutocomplete from "react-google-places-autocomplete";
//import "react-google-places-autocomplete/dist/assets/index.css";

import Geocode from "react-geocode";
import BlockUI from "../Component/BlockUI";


function AddBusinessBranch(props) {

	const history = useHistory();
	const location = useLocation();

	const bs_id = location.state.bs_id;
	function viewBusiness() {
	    history.push("/business/all")
	}

	const [initialValues, setInitialValues] = useState({ 
		business: bs_id,
		address: "",
		area:"",
		city: "",
		branchcat:"",
		country:178
	});
	const qr_box_ref = useRef(null);
	  const globalState  = useSelector(state=>state.business)

	  const [allbusinesses, setdata] = useState([]);
	  const [allcountries, setCountryData] = useState([]);
	  const [input_address,setAddress] = useState('')
	  const {user} = useSelector(state => state.auth);
	  const [addlats, SetAddlats] = useState("");
	  const [addlong, SetAddlong] = useState("");
	  const [branchCats, SetBranchCats] = useState([]);
	  const user_id = user.id; 

	  const [state, setState] = React.useState({
		business: bs_id,
		name: "",
		phone_number:"",  
		checkedA: true,
		latitude:"",
		longitude: "",
		getAddress: "",
		getAdds:null,
		getLat:null,
		getLng:null,
		address:"",
		address2:"",
		area: "",
		city:"",
		state:"",
		zip:"",
		country:178,
		valid_addr: "",
		addressMsg: "hide",
		valid_area: "",
		areaMsg:"hide",
		valid_city: "",
		cityMsg:"hide",
		branchcat: "",
		branch_cat : "",
		branchMsg:"hide",
		valid_business: "",
		businessMsg: "hide",
		valid_name: "",
		nameMsg:"hide",
		valid_phone: "",
		phoneMsg: "hide"
	  });

	  const [values, setValues] = React.useState({
		business:bs_id,
		name: "",
		phone_number: "",
		address:"",
		address2:"",
		business: "",
		area: "",
		city:"",
		state:"",
		zip:"",
		branchcat: "",
		country:178
	  });

	  useEffect( ()=>{
		  SetBranchCats(globalState.all_branch_cat)
		  setdata(globalState.all_businesses)
		  setCountryData(globalState.all_countries)
	  });

	  useEffect(() => {
		generateQRCode();
		props.getBranchCat();
	  },[globalState.new_branch_url])

	const generateQRCode = async ()=>{
		var url = globalState.new_branch_url
		if	(url!= "" && url.length>3){
			const qrCode =await  new QRCodeStyling({
				width: 1900,
				height: 1900,
				
				image: require('./qr-lite.png'),
				dotsOptions: {
				color: "#171919",
				type: "dots"
				},
			
				qrOptions: {
					typeNumber: 0,
					errorCorrectionLevel: "H",
					mode: "Byte"
				},
				cornersSquareOptions: {
				type: "extra-rounded"
				},
				cornersDotOptions: {
				type: "dot"
				},
				imageOptions: {
					crossOrigin: "anonymous",
					imageSize: 0.4,
					margin: 11
				}
			});
			qrCode.append(qr_box_ref.current);
			await  qrCode.update({	
				data: url
			});
			setTimeout(() => {		  
				var code = qrCode._canvas.getCanvas().toDataURL()	
				var branch = url.split('=')[1]
				props.uploadBrQR({qr_data:code, user:user.id, branch_id:branch, bs_id: state.business,push:history.push})
			}, 1000);
			//qrCode.download()
		}
	}
	const handleChange = name => event => {
		//console.log(name,event.target.value)
	    setState({ ...state, [name]: event.target.value, 
			valid_addr: '',
			addressMsg: "hide",
			valid_area: "", 
			areaMsg:"hide",
			valid_city: "",
			cityMsg:"hide", 
			valid_business: "",  
			businessMsg: "hide", 
			valid_name: "", 
			nameMsg:"hide", 
			valid_phone: "", 
			phoneMsg: "hide", 
			branch_cat: "", 
			branchMsg: "hide"
		});
        setValues({ ...values, [name]: event.target.value });
		//console.log(state, values)
	}; 

	useEffect( ()=>{
			props.getBusinesses(user_id)
			
		  	Geocode.setApiKey("AIzaSyBEuO4GQ_eNXuf5uGdbGcl67OoHhgoSrNI");
			Geocode.enableDebug();

			Geocode.fromAddress(state.getAddress).then(
				response => {
					const { lat, lng } = response.results[0].geometry.location;
					//console.log(lat, lng)
					SetAddlats(lat);
					SetAddlong(lng);

					Geocode.fromLatLng(lat, lng).then(
						response => {
							const address = response.results[0];
							const format_address = response.results[0].formatted_address;
							//console.log(address.address_components)
							//console.log(format_address)
		
							const formatGeocode = (result) => {
								//console.log(result.address_components)
								if (result) {
								  var address = '',
									city = '',
									new_state = '',
									area = '',
									block = '',
									zip = '',
									country = '';
							  
									for (var i = 0; i < result.address_components.length; i++) {
										var addr = result.address_components[i];
										// check if this entry in address_components has a type of country
										if (addr.types[0] == 'country') country = addr.long_name;
										else if (addr.types[0] == 'street_number')
										// address 1
										address = address + addr.long_name;
										else if (addr.types[0] == 'establishment')
										address = address + ' ' + addr.long_name;
										else if (addr.types[0] == 'neighborhood')
										// address 3
										address = address + ' ' + addr.long_name;
										else if (addr.types[0] == 'route')
										// address 2
										address = address + ' ' + addr.long_name;
										// else if (addr.types[0] == 'premise')
										//   // address 3
										//   address = address + ' ' + addr.long_name;
										else if (addr.types[0] == 'postal_code')
										// Zip
										zip = addr.short_name;
										else if (addr.types[1] == 'sublocality')
										// area
										area = addr.long_name;
										else if (addr.types[0] == ['administrative_area_level_1'])
										// State
										new_state = addr.long_name;
										else if (addr.types[0] == ['locality'])
										// City
										city = addr.long_name;
									}
									//console.log(allcountries)
									const cn_id = allcountries.find(post=>post.country_name===country);
									//console.log("Country ID: "+cn_id.id)
									// allcountries.map((option, index) => (
									// 	console.log(option.id, option.country_name)
									// ))
									setState({...state,  address:address, area:area, city:city, state:new_state, zip:zip, country:cn_id.id, addressMsg: "hide", areaMsg: "hide", cityMsg: "hide", branchMsg: "hide", businessMsg: "hide", nameMsg: "hide", phoneMsg: "hide"});
									setValues({...values, address:address, area:area, city:city, state:state, zip:zip, country:cn_id.id});
									
									
									console.log(state, values)
									
									return {
										city,
										area,
										address,
										state,
										zip,
										country,
									};
								}
							}
		
							formatGeocode(address);
						},
						error => {
							console.error(error);
						}
					);
				},
				error => {
					console.error(error);
				}
			);
	} , [state.getAddress]);
	  
	useEffect( ()=>{
		  props.getCountries()
	} , []);

	const formik = useFormik({
    	initialValues,
    	validationSchema: BranchSchema,
    	onSubmit: (values, { setStatus, setSubmitting }) => {

			if(state.branchcat == "") {
				setState({...state, branch_cat: "is-invalid", branchMsg: "show"});
				swal({
					title: "Something is Wrong",
					text: "Please Select Branch Category!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else if(state.branchcat !== "") {
				setState({...state, branch_cat: "valid", branchMsg: "hide"});
			}

			if(state.business == "") {
				setState({...state, valid_business: "is-invalid", businessMsg: "show"});
				swal({
					title: "Something is Wrong",
					text: "Please Select Business Name!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else {
				setState({...state, valid_business: "valid", businessMsg: "hide", branch_cat: "valid", branchMsg: "hide"});
			}

			if(state.name == "" || values.name == "") {
				setState({...state, valid_name: "is-invalid", nameMsg: "show"});
				swal({
					title: "Something is Wrong",
					text: "Branch name required!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else {
				setState({...state, valid_name: "valid", nameMsg: "hide", valid_business: "valid", businessMsg: "hide", branch_cat: "valid", branchMsg: "hide"});
			}

			if(state.phone_number == "") {
				setState({...state, valid_phone: "is-invalid", phoneMsg: "show"});
				swal({
					title: "Something is Wrong",
					text: "Phone number required!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else if(state.branchcat !== "") {
				setState({...state, valid_phone: "valid", phoneMsg: "hide", valid_name: "valid", nameMsg: "hide", valid_business: "valid", businessMsg: "hide", branch_cat: "valid", branchMsg: "hide"});
			}

			if(state.address == "") {
				setState({...state, valid_addr: "is-invalid", addressMsg: "show"});
				swal({
					title: "Something is Wrong",
					text: "Please Add Branch Address!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else {
				setState({...state, valid_addr: "valid", addressMsg: "hide", valid_phone: "valid", phoneMsg: "hide", valid_name: "valid", nameMsg: "hide", valid_business: "valid", businessMsg: "hide", branch_cat: "valid", branchMsg: "hide"});
			}

			if(state.area == "") {
				setState({...state, valid_area: "is-invalid", areaMsg: "show"});
				swal({
					title: "Something is Wrong",
					text: "Please Add Branch Area!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else {
				setState({...state, valid_area: "valid", areaMsg: "hide", valid_addr: "valid", addressMsg: "hide", valid_phone: "valid", phoneMsg: "hide", valid_name: "valid", nameMsg: "hide", valid_business: "valid", businessMsg: "hide", branch_cat: "valid", branchMsg: "hide"});
			}

			if(state.city == "") {
				setState({...state, valid_city: "is-invalid", cityMsg: "show"});
				swal({
					title: "Something is Wrong",
					text: "Please Add Branch City!",
					icon: "error",
					button: null,
					timer: 2000
				});
				return false
			} else {
				setState({...state, valid_city: "valid", cityMsg: "hide", valid_area: "valid", areaMsg: "hide", valid_addr: "valid", addressMsg: "hide", valid_phone: "valid", phoneMsg: "hide", valid_name: "valid", nameMsg: "hide", valid_business: "valid", businessMsg: "hide", branch_cat: "valid", branchMsg: "hide"});
			}

			
			props.addBranch({ 
				user_id :user.id,
				business_id : state.business,
				category_id : state.branchcat,
				name : state.name,
				phone_number: state.phone_number,
				address1 :state.address,
				address2 :state.address2 == undefined ? "" : state.address2,
				area :state.area,
				city : state.city,
				zip:state.zip,
				state:state.state,
				country_id :state.country,
				latitude:addlats,
				longitude:addlong
			});

			// console.log(
			// 	"user_id: "+user.id,
			// 	"business_id: "+state.business,
			// 	"Branch Cat: "+state.branchcat, 
			// 	"name: "+state.name,
			// 	"phone_number: "+state.phone_number,
			// 	"address1: "+state.address,
			// 	"address2: "+state.address2,
			// 	"area: "+state.area,
			// 	"city: "+state.city,
			// 	"zip: "+state.zip,
			// 	"state: "+state.state,
			// 	"country_id :"+state.country,
			// 	"latitude: "+addlats,
			// 	"longitude: "+addlong
			// )

	    },
	});

	const useStyles = makeStyles(theme => ({
		container: {
		    display: 'flex',
		    flexWrap: 'wrap',
		},
		textField: {
		    marginLeft: theme.spacing(1),
		    marginRight: theme.spacing(1),
		},
		dense: {
		    marginTop: theme.spacing(2),
		},
		menu: {
		    width: 200,
		},
	}));

	const classes = useStyles();
	
	const [loading, setLoading] = useState(false);

  	const BranchSchema = Yup.object().shape({ 
    	business: Yup.string()
	      	.required("Select Business"),
    	name: Yup.string()
	      	.required("Enter a branch name"),
		phone_number: Yup.string()
			.required("Phone Number require"),
    	country: Yup.string()
	      	.required("Select Country"),
	});

	const enableLoading = () => {
    	setLoading(true);
	};

	  const disableLoading = () => {
	    setLoading(false);
	};

	const getInputClasses = (fieldname) => {
	    if (formik.touched[fieldname] && formik.errors[fieldname]) {
	      return "is-invalid";
	    }

	    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
	      return "is-valid";
	    }

	    return "";
	};

	function goBack() {
		history.push("/business/all")
	}

	const [rows, setRows] = useState([]);
	const [add, setAdd] = useState(null);
	const [pid, setPid] = useState(null);

	//console.log(rows)

	return (<>
		<div className="row">
			
    		<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}/>
                  	</span>  
					<span>
						<FormattedMessage id="PAGE.ADDBRANCH.DESC" />
					</span>
					<span className="svg-icon menu-icon goBack" onClick={goBack}>
						<i className="fa fa-long-arrow-alt-left"></i>
			    	</span>
				</div>
			</div>
			{
				globalState.is_loading === true?
				<BlockUI />
				: <div> </div>
			} 
			<div className="card card-custom gutter-b col-md-12 innerBlks">
				<div className="card-header">
					<div className="card-title"><h3 className="card-label"><FormattedMessage id="PAGE.ADDBRANCH.WIDGET.TITLE" /></h3></div>
				</div>
		        <div className="card-body">
		        	
	        		<div className="row">
		                <div className="col-md-6 ffield">
							
		                	<form 
				        		autoComplete="off"
						        onSubmit={formik.handleSubmit}
						        className="form fv-plugins-bootstrap fv-plugins-framework"
				        	>
				        		{formik.status ? (
						          <div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
						            <div className="alert-text font-weight-bold">{formik.status}</div>
						          </div>
						        ) : (
						          <div>
						          </div>
						        )}
								
						        <div className={`fieldBlk ` + state.branch_cat}>
									<TextField
				                        select
										id="branchcat"
				                        name="branchcat"
				                        label="Select Branch Category"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "branchcat"
							            )}`}
										onChange={handleChange("branchcat")}
				                        value={values.branchcat}
				                    >
				                        {branchCats.map(option => (
				                          <MenuItem key={option.id} value={option.id}>
				                            {option.category_name}
				                          </MenuItem>
				                        ))}
				                    </TextField>
				                    <span className={`fv-help-block `+ state.branchMsg}>Branch Category Required</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.BRANCHCAT.HELP" />
				                    </p>
						        </div>
								
						        <div className={`fieldBlk ` + state.valid_business}>
									<TextField
				                        select
										id="business"
				                        name="business"
				                        label="Select Business"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "business"
							            )}`}
										onChange={handleChange("business")}
				                        value={values.business}
				                    >
				                        {allbusinesses.map(option => (
				                          <MenuItem key={option.id} value={option.id}>
				                            {option.name}
				                          </MenuItem>
				                        ))}
				                    </TextField>
				                    <span className={`fv-help-block `+ state.businessMsg}>Select Business Name</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.BUSINESS.HELP" />
				                    </p>
						        </div>
				                	
						        <div className={`fieldBlk ` + state.valid_name}>
				                    <TextField
										id="name"
				                        name="name"
				                        label="Branch Name"
				                        type="text"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "name"
							            )}`}
										onChange={handleChange("name")}
				                        value={values.name}
				                    />
				                    <span className={`fv-help-block `+ state.nameMsg}>Business Name Required</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.BRANCH.HELP" />
				                    </p>
			                    </div>

								<div className={`fieldBlk ` + state.valid_phone}>
				                    <TextField
										id="phone_number"
				                    	label="Phone No"
				                        name="phone_number"
				                        type="text"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "phone_number"
							            )}`}
										onChange={handleChange("phone_number")}
				                        value={values.phone_number}
				                    />
				                    <span className={`fv-help-block `+ state.phoneMsg}>Phone No Required</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.PHONE.HELP" />
				                    </p>
			                    </div>

								<div className="gaddress fieldBlk">
									<i className="fa fa-search-location"></i>
									<GooglePlacesAutocomplete
										placeholder="Search Location Address"
										inputStyle={{
											height: 52,
											fontSize: 14
										}}
										suggestionsStyles={{
											container: {
												padding: 16,
												background: "#efefef"
											},
											suggestion: {
												background: "#eee",
												cursor: "pointer"
											},
											suggestionActive: {
												background: "#bbb"
											}
										}}
										onSelect={(result) => {
											const { description, place_id } = result;
											setRows([{ description, place_id }, ...rows]);
											var address = result.description;
											var pl_id = result.place_id;
											setState({...state, getAddress: address});
											setPid(pl_id);
											setAdd(result.description);
										}}
									/>
									<p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.SEARCHADDRESS.HELP" />
				                    </p>
									<div style={{display: "none"}} className="google-result">
										{addlats}, {addlong} <br />
										{state.address} {state.area} {state.city} {state.country}
									</div>
								</div>
								
			                    <div className={`fieldBlk valid-address `+ state.valid_addr}>
				                    <TextField
				                        id="address"
				                        name="address"
				                        label="Branch Address"
				                        type="text"
				                        margin="normal"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
								            "address"
							            )}`}
										onChange={handleChange("address")}
				                        value={values.address}
				                    />
									<span className={`fv-help-block `+ state.addressMsg}>Branch Address Required</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.ADDRESS.HELP" />
				                    </p>
			                    </div>
								
			                    <div className="fieldBlk">
				                    <TextField
				                        id="address2"
				                        name="address2"
				                        label="Address Line 2"
				                        type="text"
				                        margin="normal"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
								            "address2"
							            )}`}
										onChange={handleChange("address2")}
				                        value={values.address2}
				                    />
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.ADDRESSLINE2" />
				                    </p>
			                    </div>

			                    <div className={`fieldBlk ` + state.valid_area}>
				                    <TextField
				                        id="area"
				                        name="area"
				                        label="Area"
				                        type="text"
				                        variant="outlined"
				                        className={`form-control h-auto py-0 px-0  ${getInputClasses(
								            "area"
							            )}`}
										onChange={handleChange("area")}
				                        value={values.area}
				                    />
									<span className={`fv-help-block `+ state.areaMsg}>Branch Area Required</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.AREA.HELP" />
				                    </p>
			                    </div>

			                    <div className={`fieldBlk ` + state.valid_city}>
				                    <TextField
				                        id="city"
				                        name="city"
				                        label="City"
				                        type="text"
				                        variant="outlined"
		    							className={`form-control h-auto py-0 px-0  ${getInputClasses(
								            "city"
										)}`}
										onChange={handleChange("city")}
				                        value={values.city}
				                    />
									<span className={`fv-help-block `+ state.cityMsg}>Branch City Required</span>
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.CITY.HELP" />
				                    </p>
			                    </div>

			                    <div className="fieldBlk">
				                    <TextField
				                        select
										id="country"
				                        name="country"
				                        variant="outlined"
				                        label="Select Country"
							            className={`form-control h-auto py-0 px-0  ${getInputClasses(
							              "country"
										)}`}
				                        {...formik.getFieldProps("country")}
										onChange={handleChange("country")}
				                        value={values.country}
				                    >
				                        {allcountries.map(option => (
				                          <MenuItem key={option.id} value={option.id}>
				                            {option.country_name}
				                          </MenuItem>
				                        ))}
				                    </TextField>
				                    {formik.touched.country && formik.errors.country ? (
							            <div className="fv-plugins-message-container">
							              <div className="fv-help-block">{formik.errors.country}</div>
							            </div>
							          ) : null}
				                    <p className="MuiFormHelperText-root MuiFormHelperText-contained">
				                    	<FormattedMessage id="PAGE.ADDBRANCH.COUNTRY.HELP" />
				                    </p>
			                    </div>

			                    <div className="class-submit clear">
		  					    	<Button 
							            type="submit"
							            //disabled={formik.isSubmitting}
		  					    		variant="contained" 
		  					    		color="primary" 
		  					    		className={classes.button}
		  					    	>
			  					        <FormattedMessage id="SAVE" />
			  					        {loading && <span className="ml-3 spinner spinner-white"></span>}
			  					    </Button>
			  					    <Button variant="contained" className={classes.button} onClick={viewBusiness}>
									  	<FormattedMessage id="CANCEL" />
			  					    </Button>
		  					    </div>
	  					    </form>
		                </div>
		                <div className="col-md-6 ">
		                	<img src="/media/images/branch.jpg" />
		                </div> 
	                </div>						    
				      
		        </div>
		    </div>    
			<div className="hide" ref={qr_box_ref} />
		</div>
	</>);

}

export default connect(null, bredux.actions)(AddBusinessBranch);