import React from "react";
import SVG from "react-inlinesvg";
import { FormattedMessage} from "react-intl";
import {toAbsoluteUrl} from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	table: {
	  minWidth: 650,
	},
  });

export function Menu() {

    const history = useHistory();
	const classes = useStyles();

	function goBack() {
		history.push("/dashboard")
	}

	const addCategory = () => {
		history.push("/menu/add-category");
	}

	const addProduct = () => {
		history.push("/menu/add");
	}

	return (<>
		<div className="row">

    		<div role="alert" className="alert alert-custom alert-white alert-shadow gutter-b col-md-12 page-desc">
				<div className="alert-text">
					<span className="svg-icon menu-icon">
                    	<SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-left-panel-2.svg")}/>
                  	</span> 
					<span>
                        <FormattedMessage id="PAGE.MENU.DESC" />
					</span>
					<span className="svg-icon menu-icon goBack hide" onClick={goBack}>
						<i className="fa fa-long-arrow-alt-left"></i>
			    	</span>
				</div>
			</div>

			<div className="card card-custom gutter-b col-md-12">
		        <div className="card-body">
				<div className="toolbar">
		        		<div className="form-group">
							<Button variant="contained" color="primary" className={classes.button} onClick={addCategory}>
						        Add New Category
						    </Button>
				        	<Button variant="contained" color="secondary" className={classes.button} onClick={addProduct}>
						        Add new Product
						    </Button>
		        		</div>
		        	</div>
				</div>
			</div>

		</div>
	</>);

}